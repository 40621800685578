<template>
  <BaseModal
    card-width="480px"
    no-padding
    no-toolbar
    no-toolbar-padding
    no-exit
  >
    <div class="w-full h-full">
      <BaseProgressStepBar
        :steps="steps"
        :current-step="currentStep"
        class="border-b border-border-normal"
      />
      <!-- Steps -->
      <CreateLensSpace
        v-if="currentStep === 0"
        :form="lensForm.space"
        :selected-folder="selectedFolder"
        @update:form="lensForm.space = $event"
        @form-validity="stepValidityStatus.space = $event"
        @selected-folder="handleSelectedFolder"
      />
      <ConnectAdAccount
        v-else-if="currentStep === 1"
        :form.sync="lensForm.connection"
        :space-data="lensForm.space"
        @account-selected="handleAccountSelected"
        @form-validity="stepValidityStatus.connection = $event"
      />
      <ConnectionSettings
        v-else-if="currentStep === 2"
        :form.sync="lensForm.connectionSettings"
        :selected-account="selectedAccount"
        @form-validity="stepValidityStatus.connectionSettings = $event"
      />
      <SetupBenchmarks
        v-else-if="currentStep === 3"
        :form="lensForm.benchmark"
        :selected-account="selectedAccount"
        @update:form="lensForm.benchmark = $event"
        @form-validity="stepValidityStatus.benchmark = $event"
      />

      <!-- Bottom Step Navigation Buttoms -->
      <div class="mt-auto flex items-center justify-between p-5 border-t border-border-normal">
        <BaseButton
          outlined
          @click="handleCancel"
        >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Cancel
          </BaseText>
        </BaseButton>

        <div class="flex items-center justify-end gap-3">
          <BaseButton
            outlined
            :disabled="currentStep === 0"
            @click="handleBack"
          >
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Back
            </BaseText>
          </BaseButton>
          <button
            class="black-btn-primary rounded-md text-white flex items-center gap-1"
            :class="!isLastStep ? 'py-1.5 pr-1 pl-3' : 'px-2 py-1.5'"
            :disabled="!isCurrentStepValid"
            @click="handleNext"
          >
            <BaseText
              type="label"
              size="sm"
            >
              {{ isLastStep ? 'Finish' : 'Next' }}
            </BaseText>
            <ChevronIcon
              v-if="!isLastStep"
              class-name="transform -rotate-90"
              :style="{ color: isCurrentStepValid ? '#fff' : '#A4acb9' }"
            />
          </button>
        </div>
      </div>
    </div>
  </BaseModal>
</template>
<script>
import CreateLensSpace from './CreateLensSpace.vue'
import ConnectAdAccount from './ConnectAdAccount.vue'
import ConnectionSettings from './ConnectionSettings.vue'
import SetupBenchmarks from './SetupBenchmarks.vue'
// Icons
import ChevronIcon from '../../globals/Icons/ChevronIcon.vue'

export default {
  name: 'LensCreationModal',
  components: {
    CreateLensSpace,
    ConnectAdAccount,
    ConnectionSettings,
    SetupBenchmarks,
    // Icons
    ChevronIcon
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedFolder: null,
    lensForm: {
      space: {
        companyName: '',
        currency: { value: 'USD', label: 'USD' },
        timezone: null
      },
      connection: {
        connectionId: '',
        adAccount: {}
      },
      connectionSettings: {
        goalMetric: 'roas',
        spendThreshold: null,
        attributionWindowClickType: { value: '1d', label: '1d' },
        attributionWindowViewType: { value: '7d', label: '7d' }
      },
      benchmark: {
        selectedIndustry: null,
        selectedAOV: null,
        selectedRevenue: null
      }
    },
    stepValidityStatus: {
      space: false,
      connection: false,
      connectionSettings: false,
      benchmark: false
    },
    selectedAccount: null,
    currentStep: 0,
    steps: [
      'Create a Lens Brand',
      'Connect an Ad Account',
      'Connection Settings',
      'Setup Benchmarks'
    ]
  }),
  computed: {
    isLastStep () {
      return this.currentStep === this.steps.length - 1
    },
    isCurrentStepValid () {
      switch (this.currentStep) {
        case 0:
          return this.stepValidityStatus.space
        case 1:
          return this.stepValidityStatus.connection
        case 2:
          return this.stepValidityStatus.connectionSettings
        case 3:
          return this.stepValidityStatus.benchmark
        default:
          return false
      }
    }
  },
  methods: {
    handleSelectedFolder (folder) {
      this.selectedFolder = folder
    },
    async handleComplete () {
      const { space, connection, connectionSettings, benchmark } = this.lensForm

      // Parse AOV values correctly
      let aov_min, aov_max
      if (benchmark.selectedAOV.value === '<$100') {
        aov_min = 0
        aov_max = 100
      } else if (benchmark.selectedAOV.value === '$100+') {
        aov_min = 100
        aov_max = null
      }

      // Parse GMV/Revenue values correctly
      let gmv
      switch (benchmark.selectedRevenue.value) {
        case '<$1M':
          gmv = 999999
          break
        case '$1M-$10M':
          gmv = 5000000
          break
        case '$10M+':
          gmv = 10000000
          break
        default:
          gmv = null
      }

      const payload = {
        name: space.companyName,
        // Space
        company_name: space.companyName,
        currency: space.currency.value,
        timezone: space.timezone.value,
        // Connection
        tw_connection_id: connection.connectionId,
        ad_accounts: [{
          account_id: connection.adAccount.value,
          account_name: connection.adAccount.label
        }],
        goal_metric: connectionSettings.goalMetric,
        spend_threshold: 0,
        click_attribution_window: connectionSettings.attributionWindowClickType.value,
        view_attribution_window: connectionSettings.attributionWindowViewType.value,
        // Benchmark
        niche: benchmark.selectedIndustry.value,
        aov_min,
        aov_max,
        gmv
      }

      this.$emit('complete', { payload, folderId: this.selectedFolder?.id })
    },
    handleCancel () {
      this.$emit('close')
    },
    handleBack () {
      if (this.currentStep > 0) {
        this.currentStep--
      }
    },
    handleNext () {
      if (this.isLastStep) {
        // Handle completion
        this.handleComplete()
        this.$emit('close')
      } else {
        this.currentStep++
      }
    },
    handleAccountSelected (account) {
      this.selectedAccount = account
    }
  }
}

</script>
