<template>
  <div class="p-5">
    <!-- Top Container -->
    <div v-if="!iframeUrl || hasValidAccount">
      <div
        class="h-44 relative bg-opacity-5 flex items-center justify-center rounded-md rounded-b-none border border-b-0 border-border-normal"
        style="background: rgba(56,127,243,0.05);"
      >
        <div class="dot-mask" />

        <!-- Loading overlay for just the controls -->
        <div
          v-if="initialLoading"
          class="absolute inset-0 z-50 flex items-center justify-center"
        >
          <BaseLoadingSpinnerCircle class="text-icon-normal w-8 h-8" />
        </div>

        <div
          class="mt-2 absolute w-64 flex flex-col items-center justify-center transition-opacity duration-100"
          :class="{ 'opacity-0': initialLoading }"
        >
          <!-- Ad Account -->
          <BaseSingleDropdown
            v-if="hasValidAccount"
            show-label-space
            label="Ad Account"
            :options="adAccounts"
            class="z-30 w-full"
            option-key="label"
            search-key="label"
            enable-search
            search-placeholder="Search Ad Accounts"
            :selected-obj="form.adAccount"
            use-parent-width
            @change="handleAccountChange"
          />

          <!-- Connect Meta Button -->
          <BaseButton
            class="mt-5"
            outlined
            :loading="loading"
            @click="handleConnectMeta"
          >
            <div class="flex justify-between items-center gap-1.5">
              <MetaPlatformIcon />
              <BaseText
                class="text-text-muted"
                type="label"
                size="sm"
              >
                {{ hasValidAccount ? 'Edit Connection' : 'Connect to Meta' }}
              </BaseText>
            </div>
          </BaseButton>
        </div>
      </div>

      <div class="flex items-center justify-between p-2.5 mb-5 border border-border-normal rounded-md rounded-t-none">
        <!-- Left Side -->
        <BaseText
          type="body"
          size="xs"
          class="text-text-normal"
        >
          Coming Soon
        </BaseText>

        <!-- Right Side -->
        <div class="flex gap-2.5">
          <!-- TikTok -->
          <div class="w-6 h-6 flex items-center justify-center border border-border-normal rounded-md">
            <img src="https://imagedelivery.net/90JjdkKV9bTv9MzTQOLrAg/da1bf604-03c8-4d61-d5ce-a3009b4ecb00/public">
          </div>
          <!-- Google Ads -->
          <div class="w-6 h-6 flex items-center justify-center border border-border-normal rounded-md">
            <img src="https://imagedelivery.net/90JjdkKV9bTv9MzTQOLrAg/67087404-2693-4a52-54ab-ef8e051d5d00/public">
          </div>
          <!-- LinkedIn -->
          <div class="w-6 h-6 flex items-center justify-center border border-border-normal rounded-md">
            <img src="https://imagedelivery.net/90JjdkKV9bTv9MzTQOLrAg/9a6a8bcc-b12b-4305-b938-c0f43cf20400/public">
          </div>
          <!-- Snapchat -->
          <div class="w-6 h-6 flex items-center justify-center border border-border-normal rounded-md">
            <img src="https://imagedelivery.net/90JjdkKV9bTv9MzTQOLrAg/ac179971-db15-415e-7515-715c7ed5e900/public">
          </div>
          <!-- X -->
          <div class="w-6 h-6 flex items-center justify-center border border-border-normal rounded-md">
            <img src="https://imagedelivery.net/90JjdkKV9bTv9MzTQOLrAg/2dea0bc8-6e57-4b26-8abc-a2d64008e300/public">
          </div>
          <!-- Pinterest -->
          <div class="w-6 h-6 flex items-center justify-center border border-border-normal rounded-md">
            <img src="https://imagedelivery.net/90JjdkKV9bTv9MzTQOLrAg/4a256268-93b2-479e-27eb-25683d8b1500/public">
          </div>
          <!-- App Lovin -->
          <div class="w-6 h-6 flex items-center justify-center border border-border-normal rounded-md">
            <img src="https://imagedelivery.net/90JjdkKV9bTv9MzTQOLrAg/e4024ac6-032d-4307-1084-f887112d1800/public">
          </div>
        </div>
      </div>

      <!-- Data Pipelin -->
      <div class="bg-background-normal rounded-md p-2.5">
        <div class="flex items-center justify-between pb-1">
          <BaseText
            type="label"
            size="sm"
          >
            Data Pipelines Powered by
          </BaseText>

          <!-- TW image -->
          <img src="../../../assets/images/tripleWhaleLogo.svg">
        </div>

        <!-- BaseText -->
        <BaseText
          type="body"
          size="xs"
          class="text-text-normal"
        >
          Connect your ad accounts securely with Triple Whale Orca Base.
        </BaseText>
        <BaseText
          type="body"
          size="xs"
          class="text-text-muted mt-1"
        >
          You DO NOT need a Triple Whale account to do this.
        </BaseText>
      </div>

      <!-- Note Section -->
      <div class="bg-secondary-yellow-10 rounded-md p-3 py-2 flex items-start justify-start mt-2.5 gap-0.5">
        <img src="../../../assets/images/note-icon.svg">

        <!-- BaseText -->
        <BaseText
          type="body"
          size="xs"
          class="text-secondary-yellow-300"
        >
          You need to have admin-level permissions for the Meta ad accounts you want to connect.
        </BaseText>
      </div>
    </div>

    <!-- iframe Container -->
    <div
      v-if="iframeUrl && !hasValidAccount"
      class="text-center p-4 flex flex-col items-center justify-center"
    >
      <BaseText
        type="body"
        size="sm"
        class="text-text-muted mb-3"
      >
        Authentication window opened in a new tab
      </BaseText>
      <BaseButton
        outlined
        @click="openAuthWindow"
      >
        Reopen Authentication Window
      </BaseButton>
    </div>
  </div>
</template>
<script>
import MetaPlatformIcon from '../../globals/Icons/FilterIcons/Platforms/MetaPlatformIcon.vue'
import LensAPI from '@/api/lens'

export default {
  name: 'ConnectAdAccount',
  components: {
    MetaPlatformIcon
  },
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    spaceData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      iframeUrl: null,
      loading: false,
      authWindow: null,
      adAccounts: [],
      hasValidAccount: false,
      initialLoading: true
    }
  },
  watch: {
    form: {
      deep: true,
      handler (newForm) {
        const isValid = this.validateForm(newForm)
        this.$emit('form-validity', isValid)
      }
    }
  },
  async mounted () {
    try {
      const registeredTWAccounts = await LensAPI.Lens.getTWAccountsList()

      // Check if we have a connected Facebook provider with ready integrations
      const facebookProvider = registeredTWAccounts.data.find(provider => provider.providerId === 'facebook-ads')
      this.hasValidAccount = facebookProvider &&
                            facebookProvider.providerStatus.status === 'connected' &&
                            facebookProvider.integrations.some(integration => integration.status === 'ready')

      if (this.hasValidAccount) {
        // Use the internal_tw_account_id from the response
        this.updateFormField('connectionId', registeredTWAccounts.internal_tw_account_id)

        // Map the integrations to ad accounts format
        this.adAccounts = facebookProvider.integrations
          .filter(integration => integration.status === 'ready')
          .map(integration => ({
            label: integration.name,
            value: integration.providerAccount
          }))
      }

      // Check initial form validity
      this.$emit('form-validity', this.validateForm(this.form))
    } catch (error) {
      console.error('Failed to get Meta connection URL:', error)
    } finally {
      this.initialLoading = false
    }
  },
  methods: {
    async handleConnectMeta () {
      try {
        this.loading = true

        // Check if a connection exists first
        const accountExists = await LensAPI.Lens.getTWAccounts()

        // If we have accounts, use redirect flow regardless of is_valid status
        if (accountExists.data && accountExists.data.length > 0) {
          // Use the internal_tw_account_id from the response for redirect
          const twAccountId = accountExists.data[0]?.internal_tw_account_id
          const { url } = await LensAPI.Lens.getTWRedirect(twAccountId)
          this.url = url
          this.twAccountId = twAccountId
        } else {
          // Create an account on TW only if no accounts exist
          const { internal_tw_account_id: twAccountId } = await LensAPI.Lens.createTWAccount(this.spaceData.currency.value, this.spaceData.timezone.value)
          const { url } = await LensAPI.Lens.getTWRedirect(twAccountId)
          this.url = url
          this.twAccountId = twAccountId

          // Start polling for the connection status only if no valid accounts exist
          const pollInterval = setInterval(async () => {
            try {
              console.log('Polling for connection status')
              const registeredTWAccounts = await LensAPI.Lens.getTWAccountsList()
              const facebookProvider = registeredTWAccounts.data.find(provider => provider.providerId === 'facebook-ads')

              // Check if we have a connected Facebook provider with ready integrations
              const isConnected = facebookProvider &&
                                 facebookProvider.providerStatus.status === 'connected' &&
                                 facebookProvider.integrations.some(integration => integration.status === 'ready')

              if (isConnected) {
                clearInterval(pollInterval)
                this.hasValidAccount = true
                this.updateFormField('connectionId', registeredTWAccounts.internal_tw_account_id)

                // Map the integrations to ad accounts format
                this.adAccounts = facebookProvider.integrations
                  .filter(integration => integration.status === 'ready')
                  .map(integration => ({
                    label: integration.name,
                    value: integration.providerAccount
                  }))

                // This needs to be done when we know that the tw accounts list is populated
                // this.authWindow.close()

                console.log('Connection established')
              }
            } catch (error) {
              console.log('Failed to poll for connection status:', error)
              console.log('Will try again in 5 seconds')
            }
          }, 5000) // Poll every 5 seconds
        }

        this.iframeUrl = this.url
        this.openAuthWindow()

        // We need to poll this and see when this is completed. It can also maybe use a web socket
      } catch (error) {
        console.error('Failed to get Meta connection URL:', error)
      } finally {
        this.loading = false
      }
    },
    openAuthWindow () {
      const width = 600
      const height = 600
      const left = (window.screen.width - width) / 2
      const top = (window.screen.height - height) / 2

      try {
        this.authWindow = window.open(
          this.url,
          'MetaAuth',
          `width=${width},height=${height},left=${left},top=${top}`
        )
      } catch (error) {
        console.error('Error opening auth window:', error)
      }
    },
    handleAccountChange (account) {
      // Update form
      this.updateFormField('adAccount', account)
      // Emit selected account separately
      this.$emit('account-selected', account)
    },
    updateFormField (field, value) {
      this.$emit('update:form', { ...this.form, [field]: value })
    },
    validateForm (formData) {
      // Form is valid if we have both a connectionId and an adAccount
      return !!(formData.connectionId && formData.adAccount && formData.adAccount.value)
    }
  }
}
</script>
<style>
.dot-mask {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background-image: radial-gradient(#e5e7eb 1px, transparent 1px);
  background-size: 16px 16px;
}
</style>
