<template>
  <div class="p-5">
    <div class="flex items-center gap-1.5 mb-1">
      <AnonBenchmarksIcon class="text-icon-muted" />
      <BaseText
        type="label"
        size="sm"
        class="text-text-muted"
      >
        Anonymous Benchmarks
      </BaseText>
    </div>
    <BaseText
      type="body"
      size="sm"
      class="text-text-normal w-full"
    >
      Compare your own performance metrics to 20,000 other companies.
    </BaseText>
    <div class="border border-border-normal rounded-xl flex flex-col mt-5 gap-5 px-2.5 pt-2.5 pb-3.5">
      <!-- Industry Dropdown -->
      <BaseSingleDropdown
        show-label-space
        label="Industry / Niche"
        :description="`Choose the niche that is closest to ${selectedAccount.label}.`"
        :options="industryOptions"
        class="z-30"
        option-key="label"
        search-key="label"
        enable-search
        search-placeholder="Search Industries"
        :selected-obj="form.selectedIndustry"
        use-parent-width
        @change="updateFormField('selectedIndustry',$event)"
      />

      <!-- AOV Dropdown -->
      <BaseSingleDropdown
        show-label-space
        label="Average Order Value (AOV)"
        :description="`Specify the AOV of ${selectedAccount.label} to be compared to similar companies.`"
        :options="aovOptions"
        class="z-20"
        option-key="label"
        :selected-obj="form.selectedAOV"
        use-parent-width
        @change="updateFormField('selectedAOV',$event)"
      />

      <!-- GMV/Revenue Dropdown -->
      <BaseSingleDropdown
        show-label-space
        label="GMV/Revenue"
        :description="`Specify the yearly revenue range of ${selectedAccount.label} to be compared to brands of a similar size.`"
        :options="revenueOptions"
        class="z-10"
        option-key="label"
        :selected-obj="form.selectedRevenue"
        use-parent-width
        @change="updateFormField('selectedRevenue',$event)"
      />
    </div>
  </div>
</template>

<script>
import AnonBenchmarksIcon from '../../globals/Icons/LensIcons/AnonBenchmarksIcon.vue'

export default {
  name: 'SetupBenchmarks',
  components: {
    AnonBenchmarksIcon
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    selectedAccount: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      showErrors: false,
      // Options
      industryOptions: [
        { label: 'Art', value: 'art' },
        { label: 'Baby', value: 'baby' },
        { label: 'Books', value: 'books' },
        { label: 'Clothing', value: 'clothing' },
        { label: 'Electronics', value: 'electronics' },
        { label: 'Fashion Accessories', value: 'fashion_accessories' },
        { label: 'Food & Beverages', value: 'food_beverages' },
        { label: 'Health & Beauty', value: 'health_beauty' },
        { label: 'Home & Garden', value: 'home_garden' },
        { label: 'Pet Supplies', value: 'pet_supplies' },
        { label: 'Sporting Goods', value: 'sporting_goods' },
        { label: 'Toys & Hobbies', value: 'toys_hobbies' },
        { label: 'Other', value: 'other' }
      ],
      aovOptions: [
        { label: '<$100', value: '<$100' },
        { label: '$100+', value: '$100+' }
      ],
      revenueOptions: [
        { label: '<$1M', value: '<$1M' },
        { label: '$1M-$10M', value: '$1M-$10M' },
        { label: '$10M+', value: '$10M+' }
      ]
    }
  },
  watch: {
    form: {
      deep: true,
      handler (newForm) {
        const isValid = this.validateForm(newForm)
        this.$emit('form-validity', isValid)
      }
    }
  },
  methods: {
    updateFormField (field, value) {
      this.showErrors = true // Show errors once user starts interacting
      this.$emit('update:form', { ...this.form, [field]: value })
    },
    validateForm (formData) {
      return !!(formData.selectedIndustry && formData.selectedAOV && formData.selectedRevenue)
    }
  }
}
</script>
