<template>
  <!-- Main Container -->
  <div class="h-full">
    <!-- Central Text -->
    <div
      class="flex flex-col items-center justify-center"
      style="margin-top: max(0px, min(228px, 20vh));"
    >
      <!-- Lens Img -->
      <video
        autoplay
        loop
        muted
        playsinline
        class="h-56 rounded-md object-cover -mb-11"
      >
        <source
          src="https://publicassets.foreplay.co/animated-icon-lens.webm"
          type="video/webm"
        >
      </video>

      <!-- Lens Title -->
      <BaseText
        type="heading"
        size="lg"
        class="text-text-loud font-normal mt-5"
      >
        Welcome to Lens
      </BaseText>
      <!-- Lens Description -->
      <BaseText
        type="body"
        size="md"
        class="text-text-muted mt-1"
        style="max-width: 396px; text-align: center;"
      >
        Make sense of your ad data through a creative lens with beautiful AI-first creative reporting.
      </BaseText>

      <!-- Get Started Button -->
      <BaseButton
        class="mt-5"
        primary
        icon-only
        @click="$emit('createLens')"
      >
        <div class="flex justify-between items-center gap-1 text-white pr-1">
          <PlusIcon class="w-4 h-4" />
          <BaseText
            type="label"
            size="sm"
          >
            Create & Link Brand
          </BaseText>
        </div>
      </BaseButton>
    </div>
  </div>
</template>
<script>
import PlusIcon from '../globals/Icons/PlusIcon.vue'
export default {
  name: 'LensEmptyState',
  components: {
    PlusIcon
  }
}
</script>
